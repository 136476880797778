
.product-pragraph {
background-color: #ebf4f5;
}

.product-img img {
    width: 100%;
    height: 25vw;
    object-fit: cover;
}
@media screen and (max-width:767px) {
    .reverse {
        flex-direction: column-reverse;
    }

    .product-img img {
        width: 100%;
        height: 40vw;
    }

    .product-img p {
        font-size: 15px;
    }

    .product-pragraph p {
        font-size: 15px;
    }
}