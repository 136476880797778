.home video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (width>900px) {
  .home {
    height: 85vh;
  }
}


.content {
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
}

.centered {
  top: 0vh;
  width: 100%;
}

.category-div {
  background-color: #ebf4f5;
}

.product-name div {
  color: white !important;
  background: #00B4DB;
  background: -webkit-linear-gradient(to right, #0083B0, #00B4DB);
  background: linear-gradient(to right, #0083B0, #00B4DB);
  position: relative;
}

.home-text h1 {
  font-size: 4vw;
}

.home-text {
  font-size: clamp(14px, 3vw, 25px);
}

@media screen and (max-width: 400px) {
  .home-text h1 {
    margin-top: -10px;
    font-size: clamp(20px, 1vw, 40px);
  }

  .home-text p {
    font-size: clamp(15px, 1.7vw, 18px);
  }

  .home {
    height: 45vh;
  }
}

@media screen and (max-width: 767px) {
  .home-pragraph p {
    font-size: 15px;
  }
}

.why-choose-us {
  background: #00B4DB;
  background: -webkit-linear-gradient(to right, #0083B0, #00B4DB);
  background: linear-gradient(to right, #0083B0, #00B4DB);
  color: white;
}





/*  */



.home-logo img {
  width: 22vh;
  height: 80px;
  animation: scroll 20s linear infinite;
}

.slide-track {
  width: 100%;
  display: flex;
  gap: 3em;
  overflow: hidden;
}

.slider {

  background-color: whitesmoke;
  padding: 4em 2em;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translatex(-2200%)
  }
}

@media screen and (max-width: 300px) {
  .product-div h4 {
    font-size: 1.2rem;
  }
}