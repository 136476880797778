.s-logo :hover {
  transform: scale(1.5);
  transition-delay: 0.1s;
  transition-duration: 0.2S;
}

.contact-img {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-img p {
  font-size: 18px;
}

@media screen and (width <=314px) {
  .software-logo img {
    width: 10vw;
  }
}

@media screen and (width > 314px) {
  .software-logo img {
    width: 10vw;

  }
}
.frm input{
  width: 80%;
}

/* Form Page */


/*  */
.Fields {
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-around;
}
.Fields div {
  margin-right: 10px;
}
label {
  margin: 15px;
}
.formContainer {
  width: 100% !important;
  color: dark;
  font-size: 20px;
  padding: 5px 20px 15px 20px;
  border: 1px solid rgb(191, 246, 250);
  border-radius: 3px;
}
input[type="text"] ,input[type="tel"] ,input[type="email"],input[type="number"]{
  display: inline-block;
  padding: 5px;
  width: 100%;
  border: 1px solid #2d08b4;
  border-radius: 3px;
}
input[type="submit"] {
  justify-content: center;
  background-color: #c2e1ff;
  color: black;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  font-size: 18px;
}
label {
  margin-left: 20px;
  display: block;
}

a {
  color: black;
}
.map-div iframe{
  width: 100%;
  height:40vh;
}
