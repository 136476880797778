.navbar-link a:hover {
  background-color: #0DCAD0;
  color: white;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.nav-logo {
  width: 30vh !important;
}
@media (max-width:600px){
  .nav-logo {
    width: 30vh !important;
  }
}
