.about-text {
  background-color: #ebf4f5;
}

.about-text p {
  font-size: 20px;
}

.about-cards{
  color: white !important;
  background: #00B4DB;  
  background: -webkit-linear-gradient(to right, #0083B0, #00B4DB);  
  background: linear-gradient(to right, #0083B0, #00B4DB); 
}

.masthead {
  min-height: 18vw;
  position: relative;
  background-size: cover;
  background-position: center;
  background-image: url("D:\Genxcel\genxcel\src\Used Images\About\img1.jpg");
}

.img-2 {
  background-image: url("D:\Genxcel\genxcel\src\Used Images\About\img2.jpg");
}

.img-3 {
  background-image: url("D:\Genxcel\genxcel\src\Used Images\About\img3.jpg");
}

.img-4 {
  background-image: url("D:\Genxcel\genxcel\src\Used Images\About\img4.jpg");
}

.img-5 {
  background-image: url("D:\Genxcel\genxcel\src\Used Images\About\img5.jpg");
}

.master p {
  color: white;
  font-weight: bold;
  font-size: 1vw;
  position: absolute;
  padding: 2vw;

}


.master h3 {
  color: white;
  font-weight: bold;
  font-size: 1.5vw;
  padding: 2vw;
}

@media screen and (max-width: 575px) {
  .masthead {
    height: 40vw;
  }

  .master p {
    font-size: 3.5vw;
  }

  .master h3 {
    font-size: 4vw;
  }

  .img-5 .master p {

    font-size: 2.5vw;
  }

  .img-4 .master {
    top: 20%;
    font-size: 2.5vw;
  }

}

@media screen and (max-width: 767px) {

  .about-text p {
    font-size: 15px;
  }
}