body {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



p {
  text-align: justify;

  font-size: 18px;
}

#us {
  color: #0DCAD0;
}


#a {
  display: none;
}

#b {
  display: none;
}

#c {
  display: none;
}

#d {
  display: none;
}

#e {
  display: none;
}

#f {
  display: none;
}

.h6Color a {
  cursor: pointer;
}