.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    transition: opacity 0.3s;
  }
  
  .scroll-to-top.hide {
    opacity: 0;
    pointer-events: none;
  }
  
  .scroll-to-top.show {
    opacity: 1;
  }
  
  .scroll-to-top button {
    padding: 15px 25px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
  }
  
  .scroll-to-top button:hover {
    background-color: #0056b3;
  }
  