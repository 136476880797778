.bg-success img {
    filter: brightness(50%);
}
.bg-b img{
    filter: brightness(50%);
}
.bg-blast img{
    filter: brightness(80%);
    background-color: aqua;
    
}
.bg-success h2 {
    font-size: clamp(40px, 1vw, 80px);
}

.service-page p {
    font-size: 15pt;
    line-height: 1.8rem;
}

@media (max-width:600px) {

    .service-page p {
        font-size: 12pt;
        max-lines: 20vw;
        line-height: 1.2rem;
        max-lines: inherit;
    }

    .bg-success {
        height: 30vh;
    }
}

.services-bg img {
    filter: brightness(80%);
}

@media (max-width:600px) {
    .services-bg img {
        min-width: 100vh;
        max-width: 100%;
        object-fit: cover;
    }
}

/* col-images */
.hover {
    overflow: hidden;
    position: relative;
    padding-bottom: 50%;
}

.hover-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
    transition: all 0.4s;
}

.hover img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
}

.hover-content {
    position: relative;
    z-index: 99;
}

.hover-2 .hover-overlay {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
}

.hover-2-title {
    position: absolute;
    top: 50%;
    left: 0;
    text-align: center;
    width: 100%;
    z-index: 99;
    transition: all 0.3s;
}

.hover-2-description {
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0;
    left: 0;
    text-align: center;
    z-index: 99;
    transition: all 0.3s;
}

.hover-2:hover .hover-2-title {
    transform: translateY(-1.5rem);
}

.hover-2:hover .hover-2-description {
    bottom: 2rem;
    opacity: 1;
}

.hover-2:hover .hover-overlay {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.1));
}
/* Link 1 */
.l1-text{
    background-color: #ebf4f5;
}
/* Link 2 */
.link-2 h2{
    font-size: clamp(25px, 4vw, 100px);
}
@media screen and (max-width:767px) {
    .reverse {
        flex-direction: column-reverse;
    }
}
/* Link-3 */
.Link-3 p{
    font-size: 18px;
}


































.link-img img {
    filter: brightness(50%);
}

.link-img img:hover {
    filter: brightness(0%) !important;
    display: none !important;
    visibility: hidden !important;
}























.Services-bg-img {
    height: 65vh;
}

.card1 img {
    object-fit: cover;
}

/* .Services-bg-img div {
    top: 22vw;
    left: 50%;
    padding-bottom: 2vw; */
/* transform: translate(-50%, -50%); */
/* } */

/* .Services-bg-img {
    height: 40dvw;
    width: 100%;
} */

/* .Services-bg-img img {
    height: 100%;
    width: 100%;
    filter: brightness(50%);
} */

.card-img-overlay {
    opacity: 0;
}

.card-img-overlay:hover {
    opacity: 1;
    top: 0;
    background-color: #008CBA;
    transition: .5s ease;
}

.card-img-overlay img {
    height: 30vw;
}

.servives img {
    height: 50vh;
    width: 100%;
}

@media (max-width : 330px) {
    .servives img {
        height: 40vh;
        width: 100%;
    }
}

.servives h5 {
    margin-top: 18vh;
}

/* link 1 */

.link-1 img {
    width: 100%;
    height: 100%;
    filter: brightness(70%);
}

.link-1 {
    height: 65vh;
}

@media (max-width:767px) {
    .link-1 {
        height: 100%;
    }
}

@media (max-width: 500px) {
    .link-1 {
        height: 30vh;
    }

    .link-1 h1 {
        font-size: clamp(25px, 5vw, 50px);
    }
}

.link-1 h1 {
    margin-top: 20%;
    color: white;

}

.card1 {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.card1 img {
    width: 100%;
    height: auto;
    display: block;

}

.card1 .overlayy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the background color and transparency as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    /* Text color */
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.card1 h2 {
    font-size: clamp(35px, 3vw, 100px)
}

@media (max-width: 300px) {
    .card1 {
        width: 100%;
    }

    .card1 h2 {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .card1 img {
        height: 100%;
    }
}